import React, { useState, useEffect, useCallback, Fragment  } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Table } from 'react-bootstrap';
import api from '../../api/api';
import { FormInstancesElement } from './FormInstancesElement';
import ErrorPopup from '../Alerts/ErrorPopup';
import './Forms.css';

export function FormInstances() {
    const navigate = useNavigate();
    const { formId } = useParams();
    const [error, setError] = useState(null);
    const [form, setForm] = useState(null);
    const [formInstances, setFormInstances] = useState([]);

    const handleBack = () => {
        navigate("/home");
    }

    const fetchForm = useCallback(async () => {
        try {
            const response = await api.get(`/api/forms/form/?form_id=${formId}`);
            setForm(response.data);
        } catch (error) {
            setError('Ezin izan da galdetegia eskuratu.');
        }
    }, [formId]);

    const fetchFormInstances = useCallback(async () => {
        try {
            const response = await api.get(`/api/forms/form_instances_list/?form_id=${formId}`);
            setFormInstances(response.data);
        } catch (error) {
            setError('Ezin izan dira galdetegiaren instantziak eskuratu.');
        }
    }, [formId]);

    useEffect(() => {
        fetchForm();
        fetchFormInstances();
    }, [fetchForm, fetchFormInstances]);

    const handleDeleteFormInstance = async (formInstanceUuid) => {
        try {
            await api.delete('/api/forms/form_instance/', { data: { instance_uuid: formInstanceUuid } });
            setFormInstances(prevInstances => prevInstances.filter(form_instance => form_instance.uuid !== formInstanceUuid));
        } catch (error) {
            setError('Ezin izan da instantzia ezabatu.');
            throw error; // Propagate the error to be handled in the child component
        }
    };

    const handleToggleEnrolled = async (formInstanceUuid, enrolled) => {
        try {
            await api.post('/api/forms/form_instance/enrolled/', {
                instance_uuid: formInstanceUuid,
                enrolled: enrolled,
            });
            setFormInstances(prevInstances =>
                prevInstances.map(instance =>
                    instance.uuid === formInstanceUuid
                        ? { ...instance, enrolled }
                        : instance
                )
            );
        } catch (error) {
            setError('Ezin izan da inskripzio egoera eguneratu.');
            throw error; // Propagate the error to be handled in the child component
        }
    };

    const handleToggleIncorporation = async (formInstanceUuid, incorporation) => {
        try {
            await api.post('/api/forms/form_instance/incorporation/', {
                instance_uuid: formInstanceUuid,
                incorporation: incorporation,
            });
            setFormInstances(prevInstances =>
                prevInstances.map(instance =>
                    instance.uuid === formInstanceUuid
                        ? { ...instance, incorporation }
                        : instance
                )
            );
        } catch (error) {
            setError('Ezin izan da inkorporazio egoera eguneratu.');
            throw error; // Propagate the error to be handled in the child component
        }
    };

    return (
        <Fragment>
        <ErrorPopup message={error} onClose={() => setError(null)} />
        <div className='main-content'>
            <div className='table-head-options'>
                <button className="back-button" onClick={handleBack}>Atzera</button>
            </div>
            <div className='table-main'>
                <div className="table-elements">
                    <Table>
                        <thead className="forms-header">
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Izena</th>
                                <th>Abizena 1</th>
                                <th>Abizena 2</th>
                                <th>Generoa</th>
                                <th>Jaiotze data</th>
                                <th>Helbidea</th>
                                <th>Herria</th>
                                <th>Posta kodea</th>
                                <th>Nazionalitatea</th>
                                <th>Dokumentu mota</th>
                                <th>Dokumentu zbkia</th>
                                <th>Bidaltze data</th>
                                <th>Iraungitze data</th>
                                <th>Tlf. Zbkia</th>
                                <th>1. Tutorearen izena</th>
                                <th>1. Tutorearen dokumentu mota</th>
                                <th>1. Tutorearen dokumentu zbkia</th>
                                <th>1. Tutorearen emaila</th>
                                <th>1. Tutorearen tlf. zbkia</th>
                                <th>2. Tutorearen izena</th>
                                <th>2. Tutorearen dokumentu mota</th>
                                <th>2. Tutorearen dokumentu zbkia</th>
                                <th>2. Tutorearen emaila</th>
                                <th>2. Tutorearen tlf. zbkia</th>
                                <th>Janari alergiak</th>
                                <th>Bestelako alergiak</th>
                                <th>Gaixotasunak</th>
                                <th>Komunikatu?</th>
                                <th>Klasea</th>
                                <th>Kamiseta neurria</th>
                                {
                                    form && form.choice_of_actions ? (
                                        form.choice_of_actions_json.map((choice_of_actions) => (
                                        <th key={choice_of_actions.day}>Ekintzak: {choice_of_actions.question_eu}</th>
                                        ))
                                    ) : null
                                }
                                {
                                    form && form.adventure_activity ? (
                                        <th>Abenturazko ekintzak</th>
                                    ) : null
                                }
                                <th>Arduradunaren komunikabideetan argitaratu?</th>
                                <th>Arduradunaz kanpoko komunikabideetan argitaratu?</th>
                                <th>Balioztatuta?</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td 
                                colSpan={35 + (form && form.choice_of_actions_json ? form.choice_of_actions_json.length : 0) + (form && form.adventure_activity ? 1 : 0)} 
                            >
                                <hr />
                            </td>
                        </tr>

                            {formInstances.length === 0 ? (
                                <tr>
                                    <td className='no-forms' colSpan={33}>
                                        <div>Ez dago galdetegiaren instantziarik</div>
                                    </td>
                                </tr>
                            ) : (
                                formInstances.map((formInstance, index) => (
                                    <FormInstancesElement
                                        key={formInstance.uuid}
                                        formInstance={formInstance}
                                        onDeleteFormInstance={handleDeleteFormInstance}
                                        onToggleEnrolled={handleToggleEnrolled}
                                        onToggleIncorporation={handleToggleIncorporation}
                                        rowNumber={index + 1}
                                    />
                                ))
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
        </Fragment>
    );
}
