import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import api from "../../api/api";
import { BsCardList, BsEye, BsTrash3, BsPencil, BsCloudDownload, BsLink45Deg, BsCheckCircle, BsCheckCircleFill, BsCopy, BsX } from 'react-icons/bs';
import MessagePopup from '../Alerts/MessagePopup';
import ErrorPopup from '../Alerts/ErrorPopup';
import SuccessPopup from '../Alerts/SuccessPopup';
import './Forms.css';

export function FormsElement({ form, onDeleteForm }) {
    const navigate = useNavigate();
    const [formState, setFormState] = useState(form);
    const [wpPage, setWPPage] = useState({});
    const [success, setSuccess] = useState(null);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [updateWP, setUpdateWP] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState('');
    // New state variable for selected date
    const [selectedDate, setSelectedDate] = useState(() => {
        const today = new Date();
        return today.toISOString().split('T')[0]; // format to 'YYYY-MM-DD'
    });

    useEffect(() => {
        const madridTimeFormat = new Intl.DateTimeFormat("sv-SE", {
            timeZone: "Europe/Madrid",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,  // Asegura que sea en formato 24 horas
          });
          
          const parts = madridTimeFormat.formatToParts(new Date());
          const isoMadridTime = `${parts.find(p => p.type === 'year').value}-${parts.find(p => p.type === 'month').value}-${parts.find(p => p.type === 'day').value}T${parts.find(p => p.type === 'hour').value}:${parts.find(p => p.type === 'minute').value}:${parts.find(p => p.type === 'second').value}`;
          
          setCurrentDateTime(isoMadridTime);
    }, []);

    // Nuevos estados para el modal de descarga
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
    const [selectedFields, setSelectedFields] = useState({
        main: false,
        listing: false,
        shirts: false,
        genders: false,
        ages: false,
        classrooms: false,
        activities: false,
        authorizations: false,
        news: false,
        images: false,
        allergies: false,
        locations: false
    });

    // Función para descargar los PDFs
    const handleDownloadPDF = (fileType) => {
        api.get('/api/forms/download_pdf_report/', {
            params: {
                form_id: formState.id,
                file_type: fileType,
                date: selectedDate
            },
            responseType: 'blob',
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const actualDate = new Date().toISOString().slice(0, 10).replace(/-/g, '');
            link.href = url;
            link.setAttribute('download', `${actualDate}_${formState.name}_${fileType}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch((error) => {
            setError('Ezin izan da PDF deskargatu.');
        });
    };

    const handleActivate = (form_id, is_active) => {
        if (!formState.completed){
            api.post('/api/forms/activate/', { form_id, is_active: !is_active })
            .then((response) => {
                setFormState((prevState) => ({
                    ...prevState,
                    is_active: !is_active
                }));
            })
            .catch((error) => {
                setError('Ezin izan da galdetegia aktibatu.');
            });
        }
    };

    const handleFormInstances = () => {
        navigate(`/form_instances/${form.id}`);
    };

    const handlePreview = () => {
        window.open(form.preview_link, '_blank');
    };

    const handleWP = () => {
        // Obtener los datos de Wordpress.
        getWPData(form.id);
        setUpdateWP(true);
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(wpPage.link);
        setMessage('Link-a zuzen kopiatu da.');
    };

    const handleCopyStudentsPreviewLink = () => {
        const currentDomain = window.location.origin;  // Obtiene el dominio actual
        const previewLink = `${currentDomain}/form_instances_preview/${formState.id}&datetime=${currentDateTime}`;  // Construye el link completo
        
        navigator.clipboard.writeText(previewLink)  // Copia el link al portapapeles
            .then(() => {
                setMessage('Link-a zuzen kopiatu da.');
            })
            .catch(() => {
                setMessage('Errorea kopiatzean.');
            });
    };

    const handleEdit = () => {
        navigate(`/edit_form/${form.id}`);
    };

    const handleComplete = (form_id, completed) => {
        api.post('/api/forms/complete/', { form_id, completed: completed })
        .then((response) => {
            if (completed){
                setFormState((prevState) => ({
                    ...prevState,
                    completed: completed,
                    is_active: false,
                    num_students: formState.num_completed
                }));
            } else {
                setFormState((prevState) => ({
                    ...prevState,
                    completed: completed
                }));
            }
        })
        .catch((error) => {
            setError('Ezin izan da galdetegia amaitutzat eman.');
        });
    };

    const handleDelete = (form_id) => {
        onDeleteForm(form_id);
    };

    // Modificación de handleDownload para abrir el modal y resetear los switches
    const handleDownload = (form_id) => {
        const newSelectedFields = {
            main: false,
            listing: false,
            shirts: false,
            genders: false,
            ages: false,
            classrooms: false,
            activities: false,
            authorizations: false,
            news: false,
            images: false,
            allergies: false,
            locations: false
        };

        // Configurar 'news' según 'summer_experiences'
        if (formState.summer_experiences === false) {
            newSelectedFields.news = false;
        }

        // Configurar 'authorizations' según 'adventure_activity' y 'adventure_activity_selected'
        if (formState.adventure_activity === false || formState.adventure_activity_selected == null) {
            newSelectedFields.authorizations = false;
        }

        // Configurar 'activities' según 'choice_of_actions' y 'choice_of_actions_json'
        if (formState.choice_of_actions === false || formState.choice_of_actions_json == null) {
            newSelectedFields.activities = false;
        }

        setSelectedFields(newSelectedFields);
        setIsDownloadModalOpen(true);
    };

    const confirmDownload = () => {
        // Asegurarse de que 'news' sea false si summer_experiences es false
        const fieldsToSend = { ...selectedFields };
        if (!formState.summer_experiences) {
            fieldsToSend.news = false;
        }

        // Asegurarse de que 'authorizations' y 'activities' sean false si no deben incluirse
        if (formState.adventure_activity === false || formState.adventure_activity_selected == null) {
            fieldsToSend.authorizations = false;
        }

        if (formState.choice_of_actions === false || formState.choice_of_actions_json == null) {
            fieldsToSend.activities = false;
        }

        // Realizar la llamada a la API con los parámetros seleccionados
        api.get(`/api/forms/download_reports/`, {
            params: { form_id: formState.id, ...fieldsToSend },
            responseType: 'blob', // Importante para datos binarios
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const actualDate = new Date().toISOString().slice(0, 10).replace(/-/g, '');
            link.href = url;
            link.setAttribute('download', `${actualDate}_${formState.name}_datuak.xlsx`); // o cualquier otra extensión
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsDownloadModalOpen(false); // Cerrar el modal después de la descarga
        })
        .catch((error) => {
            setError('Ezin izan dira datuak deskargatu.');
            setIsDownloadModalOpen(false);
        });
    };

    const cancelDownload = () => {
        setIsDownloadModalOpen(false);
    };

    const toggleField = (field) => {
        setSelectedFields(prevState => ({
            ...prevState,
            [field]: !prevState[field]
        }));
    };

    const getWPData = (form_id) => {
        api.get(`/api/forms/wp_page/?form_id=${form_id}`)
        .then((response) => {
            setWPPage(response.data);
        })
        .catch((error) => {
            setError('Ezin izan dira datuak deskargatu.');
        });
    };

    const handleDeleteConfirm = () => {
        setConfirmDelete(true);
    };

    const handleDeleteCancel = () => {
        setConfirmDelete(false);
    };

    const handleDeleteConfirmProceed = () => {
        handleDelete(form.id);
        setConfirmDelete(false);
    };

    const handleOverlayClick = (event) => {
        if (event.target.className === 'modal-overlay') {
            setConfirmDelete(false);
            setIsDownloadModalOpen(false);
            setUpdateWP(false);
        }
    };

    const handleWPTitleSave = () => {
        setIsSubmitting(true);
        api.post('/api/forms/wp_title/', { 
            form_id: form.id, 
            title: wpPage.title 
        })
        .then((response) => {
            setSuccess('Izenburua ondo eguneratu da.');
            getWPData(form.id);
            setIsSubmitting(false);
        })
        .catch((error) => {
            setError('Ezin izan da izenburua eguneratu.');
            setIsSubmitting(false);
        });
    };
    
    const handleWPLinkSave = () => {
        setIsSubmitting(true);
        api.post('/api/forms/wp_slug/', { 
            form_id: form.id, 
            slug: wpPage.slug 
        })
        .then((response) => {
            setSuccess('Link-a ondo eguneratu da.');
            getWPData(form.id);
            setIsSubmitting(false);
        })
        .catch((error) => {
            setError('Ezin izan da link-a eguneratu.');
            setIsSubmitting(false);
        });
    };

    const handleChangesDate = () => {
        setIsSubmitting(true);
        api.post('/api/forms/changes_date/', { 
            form_id: form.id, 
            changes_date: formState.changes_date 
        })
        .then((response) => {
            setSuccess('Aldaketen data ondo eguneratu da.');
            getWPData(form.id);
            setIsSubmitting(false);
        })
        .catch((error) => {
            setError('Ezin izan da aldaketen data eguneratu.');
            setIsSubmitting(false);
        });
    };

    // Agregamos las funciones onChange para actualizar el estado
    const handleTitleChange = (e) => {
        setWPPage((prevState) => ({
            ...prevState,
            title: e.target.value,
        }));
    };

    const handleLinkChange = (e) => {
        setWPPage((prevState) => ({
            ...prevState,
            slug: e.target.value,
        }));
    };

    const handleChangesDateChange = (e) => {
        setFormState((prevState) => ({
            ...prevState,
            changes_date: e.target.value
        }));
    };

    // Determinar si el botón de descarga debe estar deshabilitado
    const isDownloadDisabled = Object.values(selectedFields).every(field => field === false);

    return (
        <>
            <MessagePopup message={message} onClose={() => setMessage(null)} />
            <ErrorPopup message={error} onClose={() => setError(null)} />
            <SuccessPopup message={success} onClose={() => setSuccess(null)} />
            {confirmDelete && (
                <div className="modal-overlay" onClick={handleOverlayClick}>
                    <div className="modal-content">
                        <p>Ziur zaude galdetegia ezabatu nahi duzula?</p>
                        <button className='delete-button' onClick={handleDeleteConfirmProceed}>Ezabatu</button>
                        <button className='back-button' onClick={handleDeleteCancel}>Atzera</button>
                    </div>
                </div>
            )}
            {updateWP && (
                <div className="modal-overlay" onClick={handleOverlayClick}>
                    <div className="modal-content link-update">
                        <span className='close-modal-span' onClick={() => setUpdateWP(false)}>
                            <BsX />
                        </span>
                        <p className='modal-name'>WordPress orrialdearen datuak</p>
                        <div>
                            <input className='inmutable-input' type='text' value={wpPage.link} readOnly></input>
                            <span className='icon-span' onClick={() => handleCopyLink()}>
                                <BsCopy />
                            </span>
                        </div>
                        <div className='update-div'>
                            <span>
                                Izenburua:
                            </span>
                            <input type='text' value={wpPage.title} onChange={handleTitleChange}></input>
                        </div>
                        <button className='create-button' onClick={handleWPTitleSave} disabled={isSubmitting}>Gorde</button>
                        <div className='update-div'>
                            <span>Link-a:</span>
                            <input type='text' value={wpPage.slug} onChange={handleLinkChange}></input>
                        </div>
                        <button className='create-button' onClick={handleWPLinkSave} disabled={isSubmitting}>Gorde</button>
                        <p className='modal-name'>Ikasleen datuen aurrebista</p>
                        <div>
                            <input className='inmutable-input' type='text' value={`${window.location.origin}/form_instances_preview/${formState.id}&datetime=${currentDateTime}`} readOnly></input>
                            <span className='icon-span' onClick={() => handleCopyStudentsPreviewLink()}>
                                <BsCopy />
                            </span>
                        </div>
                        <p className='modal-name'>Ikasleen datuen aurrebista aldaketak</p>
                        <div className='update-div'>
                            <span>Data:</span>
                            <input type="date" name="endDate" value={formState.changes_date} onChange={handleChangesDateChange} />
                        </div>
                        <button className='create-button' onClick={handleChangesDate} disabled={isSubmitting}>Gorde</button>
                    </div>
                </div>
            )}
            {isDownloadModalOpen && (
                <div className="modal-overlay" onClick={handleOverlayClick}>
                    <div className="modal-content">
                        <span className='close-modal-span' onClick={() => setIsDownloadModalOpen(false)}>
                            <BsX />
                        </span>
                        <p className='modal-name'>Aukeratu deskargatu nahi dituzun eremuak</p>
                        <form>
                            <div className='switch-group-main'>
                                <div className='switch-group'>
                                    <div>
                                        <label>Nagusia</label>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={selectedFields.main}
                                                onChange={() => toggleField('main')}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                    <div>
                                        <label>Zerrenda</label>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={selectedFields.listing}
                                                onChange={() => toggleField('listing')}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                    <div>
                                        <label>Kamisetak</label>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={selectedFields.shirts}
                                                onChange={() => toggleField('shirts')}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                    <div>
                                        <label>Generoa</label>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={selectedFields.genders}
                                                onChange={() => toggleField('genders')}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                    <div>
                                        <label>Adina</label>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={selectedFields.ages}
                                                onChange={() => toggleField('ages')}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                    <div>
                                        <label>Klaseak</label>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={selectedFields.classrooms}
                                                onChange={() => toggleField('classrooms')}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                    {formState.choice_of_actions && formState.choice_of_actions_json && (
                                        <div>
                                            <label>Ekintzak</label>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedFields.activities}
                                                    onChange={() => toggleField('activities')}
                                                />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                    )}
                                    {formState.adventure_activity && formState.adventure_activity_selected && (
                                        <div>
                                            <label>Ekintzen baimenak</label>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedFields.authorizations}
                                                    onChange={() => toggleField('authorizations')}
                                                />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                    )}
                                    {formState.summer_experiences && (
                                        <div>
                                            <label>Notiziak</label>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedFields.news}
                                                    onChange={() => toggleField('news')}
                                                />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                    )}
                                    <div>
                                        <label>Irudien erabilera</label>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={selectedFields.images}
                                                onChange={() => toggleField('images')}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                    <div>
                                        <label>Alergiak</label>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={selectedFields.allergies}
                                                onChange={() => toggleField('allergies')}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                    <div>
                                        <label>Herriak</label>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={selectedFields.locations}
                                                onChange={() => toggleField('locations')}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className='modal-buttons'>
                            <button 
                                className='create-button' 
                                onClick={confirmDownload} 
                                disabled={isDownloadDisabled}
                            >
                                Deskargatu
                            </button>
                        </div>
                        <hr></hr>
                        <div className='modal-date'>
                            <input 
                                type="date" 
                                value={selectedDate} 
                                onChange={(e) => setSelectedDate(e.target.value)} 
                            />
                        </div>
                        <div className='modal-buttons'>
                            <button 
                                className='create-button' 
                                onClick={() => handleDownloadPDF('listing')}
                            >
                                Deskargatu Zerrendaren PDF
                            </button>
                        </div>
                        <div className='modal-buttons'>
                            <button 
                                className='create-button' 
                                onClick={() => handleDownloadPDF('classrooms')}
                            >
                                Deskargatu Klaseen PDF
                            </button>
                        </div>
                        <hr></hr>
                        <div className='modal-buttons'>
                            {formState.choice_of_actions && formState.choice_of_actions_json && (
                                <button 
                                    className='create-button' 
                                    onClick={() => handleDownloadPDF('actions')}
                                >
                                    Deskargatu Ekintzen PDF
                                </button>
                            )}
                        </div>
                        <div className='modal-buttons'>
                            <button className='back-button' onClick={cancelDownload}>Atzera</button>
                        </div>
                    </div>
                </div>
            )}
            <tr className='form-element'>
                <td className='white-bg'>
                    <div className='clickable-icons'>
                        <span
                            className={formState.num_completed === 0 ? 'unclickable-span' : 'clickable-span'}
                            onClick={formState.num_completed !== 0 ? () => handleFormInstances(formState.id) : null}
                        >
                            <BsCardList />
                        </span>
                    </div>        
                </td>
                <td>{formState.name}</td>
                <td>{formState.destination}</td>
                <td>{formState.start_date}</td>
                <td>{formState.end_date}</td>
                <td>{formState.contact_email}</td>
                <td>{formState.user}</td>
                <td>{formState.num_enrolled}/{formState.num_students}</td>
                <td className='td-right'>
                    <div className='clickable-icons'>
                        <span className='clickable-span' onClick={() => handlePreview(formState.id)}>
                            <BsEye />
                        </span>
                        {' '}
                        <span className='clickable-span' onClick={() => handleEdit(formState.id)}>
                            <BsPencil />
                        </span>
                        {' '}
                        <span className='clickable-span' onClick={() => handleWP(formState.id)}>
                            <BsLink45Deg className='bigger-icon-2'/>
                        </span>
                        {' '}
                        <span
                            className={formState.num_completed === 0 ? 'unclickable-span' : 'clickable-span'}
                            onClick={formState.num_completed !== 0 ? () => handleDownload(formState.id) : null}
                        >
                            <BsCloudDownload />
                        </span>
                        {' '}
                        <span className='clickable-span' onClick={handleDeleteConfirm}>
                            <BsTrash3 />
                        </span>
                        {' '}
                        {formState.completed && (
                            <span className='clickable-span' onClick={() => handleComplete(formState.id, false)}>
                                <BsCheckCircleFill className='bigger-icon green-color'/>
                            </span>
                        )}
                        {!formState.completed && (
                            <span className='clickable-span' onClick={() => handleComplete(formState.id, true)}>
                                <BsCheckCircle className='bigger-icon'/>
                            </span>
                        )}
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={formState.is_active}
                                onChange={() => handleActivate(formState.id, formState.is_active)}
                            />
                            <span className="slider"></span>
                        </label>
                    </div>
                </td>
            </tr>
        </>
    );
}
