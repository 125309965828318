import React, { useState } from 'react';
import { BsTrash3, BsPencil, BsBackpack, BsBackpackFill, BsBookmarkPlus, BsBookmarkFill } from 'react-icons/bs';
import MessagePopup from '../Alerts/MessagePopup';
import ErrorPopup from '../Alerts/ErrorPopup';
import './Forms.css';

export function FormInstancesElement({ formInstance, onDeleteFormInstance, onToggleEnrolled, onToggleIncorporation, rowNumber }) {
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const isNew = formInstance.changed_fields && formInstance.changed_fields.includes('new');

    const fieldChanged = (fieldName) => {
        return formInstance.changed_fields && formInstance.changed_fields.includes(fieldName);
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        try {
            await onDeleteFormInstance(formInstance.uuid);
            setMessage("Instantzia zuzen ezabatu da.");
        } catch (err) {
            setError('Ezin izan da instantzia ezabatu.');
        } finally {
            setIsDeleting(false);
            setConfirmDelete(false);
        }
    };

    const handleDeleteConfirm = () => {
        setConfirmDelete(true);
    };

    const handleDeleteCancel = () => {
        setConfirmDelete(false);
    };

    const handleOverlayClick = (event) => {
        if (event.target.className === 'modal-overlay') {
            setConfirmDelete(false);
        }
    };

    const handleEdit = () => {
        if (formInstance.wp_page) {
            window.open(formInstance.wp_page, '_blank');
        } else {
            setError('Eguneraketa URLa ez dago eskuragarri instantzia honentzat.');
        }
    };

    const handleToggleEnrolled = async () => {
        try {
            await onToggleEnrolled(formInstance.uuid, !formInstance.enrolled);
        } catch (error) {
            setError('Errorea izen ematearen egoera eguneratzean.');
        }
    };

    const handleToggleIncorporation = async () => {
        try {
            await onToggleIncorporation(formInstance.uuid, !formInstance.incorporation);
        } catch (error) {
            setError('Errorea inkorporazioaren egoera egunenratzean.Error al actualizar el estado de incorporación.');
        }
    };

    // Function to get the class name for a cell based on priority
    const getCellClassName = (fieldName) => {
        let classNames = [];

        if (isNew) {
            classNames.push('new-instance');
        }

        if (formInstance.incorporation === true) {
            classNames.push('incorporation');
        }

        if (formInstance.enrolled === false) {
            classNames.push('enrolled-false');
        }

        if (fieldChanged(fieldName)) {
            classNames.push('changed-field');
        }

        return classNames.join(' ');
    };

    return (
        <>
            <MessagePopup message={message} onClose={() => setMessage(null)} />
            <ErrorPopup message={error} onClose={() => setError(null)} />
            {confirmDelete && (
                <div className="modal-overlay" onClick={handleOverlayClick}>
                    <div className="modal-content">
                        <p>Ziur zaude instantzia ezabatu nahi duzula?</p>
                        <button className='delete-button' onClick={handleDelete} disabled={isDeleting}>
                            {isDeleting ? 'Ezabatzen...' : 'Ezabatu'}
                        </button>
                        <button className='back-button' onClick={handleDeleteCancel}>Atzera</button>
                    </div>
                </div>
            )}
            <tr className='form-element'>
                <td className='white-bg'>
                    <div className='instance-icons'>
                        <span 
                            className='clickable-span' 
                            onClick={handleEdit}
                            title="Eguneratu"
                        >
                            <BsPencil />
                        </span>
                        <span 
                            className='clickable-span' 
                            onClick={handleDeleteConfirm}
                            title="Ezabatu"
                        >
                            <BsTrash3 />
                        </span>
                        <span 
                            className='clickable-span' 
                            onClick={handleToggleEnrolled}
                            title="Inskripzioaren egoera aldatu"
                        >
                            {formInstance.enrolled ? <BsBackpackFill /> : <BsBackpack />}
                        </span>
                        <span 
                            className='clickable-span' 
                            onClick={handleToggleIncorporation}
                            title="Inkorporazioaren egoera aldatu"
                        >
                            {formInstance.incorporation ? <BsBookmarkFill /> : <BsBookmarkPlus />}
                        </span>
                    </div>
                </td>
                <td className={getCellClassName('')}>{rowNumber}</td>
                <td className={getCellClassName('name')}>{formInstance.name}</td>
                <td className={getCellClassName('surname_1')}>{formInstance.surname_1}</td>
                <td className={getCellClassName('surname_2')}>{formInstance.surname_2}</td>
                <td className={getCellClassName('gender')}>{formInstance.gender}</td>
                <td className={getCellClassName('birthdate')}>{formInstance.birthdate}</td>
                <td className={getCellClassName('address')}>{formInstance.address}</td>
                <td className={getCellClassName('location')}>{formInstance.location}</td>
                <td className={getCellClassName('zip_code')}>{formInstance.zip_code}</td>
                <td className={getCellClassName('nationality')}>{formInstance.nationality}</td>
                <td className={getCellClassName('document_type')}>{formInstance.document_type}</td>
                <td className={getCellClassName('document_number')}>{formInstance.document_number}</td>
                <td className={getCellClassName('expedition_date')}>{formInstance.expedition_date}</td>
                <td className={getCellClassName('expiration_date')}>{formInstance.expiration_date}</td>
                <td className={getCellClassName('phone')}>{formInstance.phone}</td>
                <td className={getCellClassName('tutor_fullname')}>{formInstance.tutor_fullname}</td>
                <td className={getCellClassName('tutor_document_type')}>{formInstance.tutor_document_type}</td>
                <td className={getCellClassName('tutor_document_number')}>{formInstance.tutor_document_number}</td>
                <td className={getCellClassName('tutor_email')}>{formInstance.tutor_email}</td>
                <td className={getCellClassName('tutor_phone')}>{formInstance.tutor_phone}</td>
                <td className={getCellClassName('tutor_fullname_2')}>{formInstance.tutor_fullname_2}</td>
                <td className={getCellClassName('tutor_document_type_2')}>{formInstance.tutor_document_type_2}</td>
                <td className={getCellClassName('tutor_document_number_2')}>{formInstance.tutor_document_number_2}</td>
                <td className={getCellClassName('tutor_email_2')}>{formInstance.tutor_email_2}</td>
                <td className={getCellClassName('tutor_phone_2')}>{formInstance.tutor_phone_2}</td>
                <td className={getCellClassName('food_allergies')}>{formInstance.food_allergies}</td>
                <td className={getCellClassName('other_allergies')}>{formInstance.other_allergies}</td>
                <td className={getCellClassName('diseases')}>{formInstance.diseases}</td>
                <td className={getCellClassName('communicate')}>{formInstance.communicate}</td>
                <td className={getCellClassName('classroom')}>{formInstance.classroom}</td>
                <td className={getCellClassName('shirt_size')}>{formInstance.shirt_size}</td>
                {/* Handle selected_actions */}
                {formInstance && formInstance.selected_actions ? (
                    Object.entries(formInstance.selected_actions).map(([key, actions], index) => (
                        <td 
                            key={index} 
                            className={getCellClassName('selected_actions')}
                        >
                            {actions.join(", ")}
                        </td>
                    ))
                ) : null}
                {/* Handle adventure_activity_choice */}
                {formInstance && formInstance.adventure_activity_choice ? (
                    <td className={getCellClassName('adventure_activity_choice')}>
                        {formInstance.adventure_activity_choice}
                    </td>
                ) : null}
                <td className={getCellClassName('record_images_responsable_checked')}>
                    {formInstance.record_images_responsable_checked}
                </td>
                <td className={getCellClassName('record_images_social_media_checked')}>
                    {formInstance.record_images_social_media_checked}
                </td>
                <td className={getCellClassName('verified')}>{formInstance.verified}</td>
            </tr>
        </>
    );
}
