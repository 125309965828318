import React, { useState, useEffect, useCallback, useMemo, Fragment  } from 'react';
import { useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap';
import api from '../../api/api';
import { FormsElement } from './FormsElement';
import ErrorPopup from '../Alerts/ErrorPopup';
import { BsPerson, BsPeople } from 'react-icons/bs';
import './Forms.css';

export function Forms() {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [forms, setForms] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedYear, setSelectedYear] = useState('');

    const handleCreate = () => {
        navigate('/create_form');
    }

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const fetchForms = useCallback(async () => {
        api.get('/api/forms/forms_list/')
        .then((response) => {
            setForms(response.data);
        })
        .catch((error) => {
            setError('Ezin izan dira galdetegiak eskuratu.');
        });
    }, []);

    useEffect(() => {
        fetchForms(); // Llama a fetchForms al montar el componente
    }, [fetchForms]);

    const handleDeleteForm = (formId) => {
        api.delete('/api/forms/form/', { data: { form_id: formId } })
        .then((response) => {
            setForms(forms.filter(form => form.id !== formId));
        })
        .catch((error) => {
            setError('Ezin izan da galdetegia ezabatu.');
        }); 
    };

    const filteredForms = useMemo(() => {
        let filtered = forms;
    
        // Filtrar por año si se ha seleccionado uno
        if (selectedYear) {
            filtered = filtered.filter(form => {
                if (form.start_date) {
                    const year = new Date(form.start_date).getFullYear();
                    return year.toString() === selectedYear;
                }
                return false;
            });
        }
    
        // Filtrar por texto de búsqueda
        if (searchText.trim() !== '') {
            const lowercasedSearchText = searchText.toLowerCase();
            filtered = filtered.filter(form => {
                return (
                    (form.name && form.name.toLowerCase().includes(lowercasedSearchText)) ||
                    (form.destination && form.destination.toLowerCase().includes(lowercasedSearchText)) ||
                    (form.start_date && form.start_date.toLowerCase().includes(lowercasedSearchText)) ||
                    (form.end_date && form.end_date.toLowerCase().includes(lowercasedSearchText)) ||
                    (form.contact_email && form.contact_email.toLowerCase().includes(lowercasedSearchText))
                );
            });
        }
    
        return filtered;
    }, [forms, searchText, selectedYear]);

    const uniqueYears = useMemo(() => {
        const yearsSet = new Set();
        forms.forEach(form => {
            if (form.start_date) {
                const year = new Date(form.start_date).getFullYear();
                if (!isNaN(year)) {
                    yearsSet.add(year);
                }
            }
        });
        return Array.from(yearsSet).sort((a, b) => b - a); // Orden descendente
    }, [forms]);

    return (
        <Fragment>
        <ErrorPopup message={error} onClose={() => setError(null)} />
        <div className='main-content'>
            <div className='table-head-options'>
                <div className='table-head-filters'>
                    <input className="table-head-search" type='text' placeholder='Bilatu...' value={searchText} onChange={handleSearchChange}/>
                    <select
                        className="table-head-select"
                        value={selectedYear}
                        onChange={handleYearChange}
                    >
                        <option value=''>Urtea (hasierako data)</option>
                        {uniqueYears.map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>

                </div>
                <button className='create-button' onClick={handleCreate}>Sortu</button>
            </div>
            <div className='table-main'>
                <div className="table-elements">
                    <Table>
                        <thead className="forms-header">
                            <tr>
                                <th></th>
                                <th>Izena</th>
                                <th>Helmuga</th>
                                <th>Hasiera data</th>
                                <th>Amaiera data</th>
                                <th>Kontaktua</th>
                                <th><span><BsPerson/></span></th>
                                <th><span><BsPeople/></span></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={9}>
                                    <hr />
                                </td>
                            </tr>
                            {filteredForms.length === 0 ? (
                                <tr>
                                    <td className='no-forms' colSpan={6}>
                                        <div>Ez dago galdetegirik</div>
                                    </td>
                                </tr>
                            ) : (
                                filteredForms.map((form) => (
                                    <FormsElement 
                                        key={form.id} 
                                        form={form}
                                        onDeleteForm={handleDeleteForm}
                                    />
                                ))
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
        </Fragment>
    );
}
